import Utils from 'src/common/Utils';
import httpMethod from './httpMethod';
import { APIConfig, BASE_API, PARENT_API } from '../requests/ApiConfig';
import { get } from './httpMethodPA';

class SiteService {
    getHome() {
        return httpMethod.get(APIConfig.URL_GET_HOME);
    }

    getPolicy() {
        return httpMethod.get(APIConfig.GET_POLICY);
    }

    getDetailSite({ id, cate = '', location = '', slug = '' }) {
        if (!id) {
            return httpMethod.get(
                `${APIConfig.GET_DETAIL_SITE}${Utils.convertToQuery({
                    slug: slug,
                })}`,
            );
        }
        return httpMethod.get(
            `${APIConfig.GET_DETAIL_SITE}${Utils.convertToQuery({
                siteId: id,
                cate: cate,
                location: location,
                slug: slug,
            })}`,
        );
    }

    getAbout() {
        return httpMethod.get(APIConfig.GET_ABOUT);
    }

    getListSite() {
        // return httpMethod.get(APIConfig.URL_GET_LIST_SITE);
        return httpMethod.get(`${APIConfig.URL_GET_LIST_SITE}?schedule=1`);
    }

    getFranchiseDetail({ id, isSubpage = false, subPage = '', slug = '' }) {
        if (isSubpage) {
            return httpMethod.get(
                `${APIConfig.GET_DETAIL_SITE}${Utils.convertToQuery({
                    siteId: id,
                    slug: slug,
                    subpage: subPage,
                })}`,
            );
        }

        return httpMethod.get(
            `${APIConfig.GET_DETAIL_SITE}${Utils.convertToQuery({
                siteId: id,
                slug: slug,
            })}`,
        );
    }

    getListNews({ cate = '', page, alias = '' }) {
        return httpMethod.get(
            `${APIConfig.GET_LIST_NEWS}${Utils.convertToQuery({
                cate: cate,
                page: page,
                alias: alias,
            })}`,
        );
    }

    getAllListNews({ data }) {
        return httpMethod.get(
            `${APIConfig.GET_LIST_ALL_NEWS}${Utils.convertToQuery({
                data: data,
            })}`,
        );
    }

    getDetailNews({ slug }) {
        return httpMethod.get(
            `${APIConfig.DETAIL_ARTICLE}${Utils.convertToQuery({
                slug: slug,
            })}`,
        );
    }

    sendForm({ param }) {
        return httpMethod.post(`${APIConfig.SEND_FORM}`, param);
    }

    sendEmail({ param }) {
        return httpMethod.post(APIConfig.SEND_EMAIL, param);
    }

    getFAQ() {
        return httpMethod.get(APIConfig.GET_LIST_FAQ);
    }

    getFooterConfig() {
        return httpMethod.get(APIConfig.GET_FOOTER_CONFIG);
    }

    getListCourse({ company_id, location_id = '', course_type }) {
        return httpMethod.get(
            `${APIConfig.GET_LIST_COURSE}${Utils.convertToQuery({
                company_id: company_id,
                location_id: location_id,
                type: course_type,
            })}`,
        );
    }

    getListCourseNearlyHasCamp({ company_id, course_type }) {
        return httpMethod.get(
            `${APIConfig.GET_LIST_COURSE_NEARLY}${Utils.convertToQuery({
                company_id: company_id,
                type: course_type,
            })}`,
        );
    }

    checkEmail(body) {
        return httpMethod.post(
            // `${PARENT_API}api/v2/email-check`,
            `https://www.parentarea.co/api/v2/email-check`,
            body,
        );
    }

    getTrustPilot() {
        return httpMethod.get(
            `${APIConfig.TRUST_PILOT}?cfg[0]=truspilot_rating&cfg[1]=trustpilot_max_rate&cfg[2]=trustpilot_reviews`,
        );
    }

    searchArticle(param) {
        return httpMethod.post(`${APIConfig.URL_SEARCH}`, param);
    }

    searchFAQ(cate = '') {
        return httpMethod.get(
            `${APIConfig.GET_LIST_FAQ}${Utils.convertToQuery({
                cate: cate,
            })}`,
        );
    }

    getStatusPaymentPA(param) {
        return get(`${APIConfig.GET_STATUS_PAYMENT + param}`);
        // return httpMethodPA.get(`${APIConfig.GET_STATUS_PAYMENT + param}}`);
    }

    getAreaLocation(areaID) {
        return get(APIConfig.AREA_LOCATION(areaID));
    }

    getAreaUSLocation(areaID) {
        return httpMethod.get(APIConfig.AREA_LOCATION(areaID));
    }

    getListSiteUS() {
        return httpMethod.get(
            `${process.env.NEXT_PUBLIC_BASE_URL_US_API}site/list-site`,
        );
    }

    getAllListUSNews({ data }) {
        return httpMethod.get(
            `${process.env.NEXT_PUBLIC_BASE_URL_US_API}article/search-news${Utils.convertToQuery(
                {
                    data: data,
                },
            )}`,
        );
    }

    getUSFranchiseDetail({ id, isSubpage = false, subPage = '', slug = '' }) {
        if (isSubpage) {
            return httpMethod.get(
                `${process.env.NEXT_PUBLIC_BASE_URL_US_API}site/detail-site${Utils.convertToQuery({
                    siteId: id,
                    slug: slug,
                    subpage: subPage,
                })}`,
            );
        }

        return httpMethod.get(
            `${process.env.NEXT_PUBLIC_BASE_URL_US_API}site/detail-site${Utils.convertToQuery({
                siteId: id,
                slug: slug,
            })}`,
        );
    }

    sendEmailUS({ param }) {
        return httpMethod.post(`${process.env.NEXT_PUBLIC_BASE_URL_US_API}site/send-email`, param);
    }

    forwardHeaders(headers) {
        httpMethod.forwardHeaders(headers);
    }
}

export default new SiteService();
