/* eslint-disable class-methods-use-this */
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { siteActionType } from 'src/redux/actions/actionTypes';
import { STORAGE_URL } from 'src/requests/ApiConfig';
import siteService from 'src/services/siteService';
import Constants from 'src/common/Constants';
import ModelManager from './ModelManager';
import getCountryLocal from 'src/hooks/useGetCountryLocal';
import { useSelector } from 'react-redux';
import PathRoute from './PathRoute';
import { isString } from 'lodash';
import countries from './Countries';

const { hasOwnProperty } = Object.prototype;
const GB = 'GB';
const US = 'US';
// const country = useSelector((state) => state.headerReducer.country);
class Utils {
    getCountryPathName(pathname) {
        if (isString(pathname)) {
            const countrySplited = pathname.split('/')[1];
            const countryFound = countries.find(
                (el) => el.country === countrySplited,
            );
            if (countryFound) {
                return {
                    country: `${pathname}`,
                    lang: countryFound.lang,
                };
            }

            return {
                country: `${pathname}`,
                lang: countries[0].lang,
            };
        }

        return {
            country: `${pathname}`,
            lang: countries[0].lang,
        };
    }

    getCountryPathNameReverse(pathname) {
        if (isString(pathname)) {
            const countrySplited = pathname.split('/')[1];
            const countryFound = countries.find(
                (el) => el.country === countrySplited,
            );
            if (countryFound) {
                return {
                    country: `${pathname.replace('/us', '')}`,
                    lang: countries[0].lang,
                };
            }
            return {
                country: `${'/us' + pathname}`,
                lang: countries[1].lang,
            };
        }

        return {
            country: `${'/us' + pathname}`,
            lang: countries[1].lang,
        };
    }

    getCountryPathNameUK(pathname) {
        if (isString(pathname)) {
            const countrySplited = pathname.split('/')[1];
            const countryFound = countries.find(
                (el) => el.country === countrySplited,
            );
            if (countryFound) {
                return {
                    country: `${pathname.replace('/us', '')}`,
                    lang: countries[0].lang,
                };
            }

            return {
                country: `${pathname}`,
                lang: countries[0].lang,
            };
        }

        return {
            country: `${pathname}`,
            lang: countries[0].lang,
        };
    }

    getThumb(_alias, crop) {
        // console.log(process.env.STORAGE_URL, 'STORAGE_URL');

        if (!_alias) return null;
        let alias = _alias;
        if (!alias.startsWith('http')) {
            if (alias.startsWith('/')) {
                alias = alias.substring(1);
            }
            alias = STORAGE_URL + alias;
        }

        // console.log(alias, 'STORAGE_URL');

        let realUrl = alias;
        if (crop && crop !== '') {
            const pos = alias.lastIndexOf('/');
            let baseUrl = alias.substring(0, pos);
            if (baseUrl.slice(-1) === '/') {
                baseUrl += crop;
            } else {
                baseUrl = `${baseUrl}/${crop}`;
            }
            realUrl = `${baseUrl}${alias.substring(pos)}`;
        }

        return realUrl;
    }

    checkEmail(email) {
        const emailCheck =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailCheck.test(String(email).toLowerCase());
    }

    checkPhone(phone) {
        phone = phone.replace(/\s/g, '');
        const phoneCheck = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/g;
        return phoneCheck.test(String(phone).toLowerCase());
    }

    isEmpty(obj) {
        // null and undefined are "empty"
        if (obj == null) {
            return true;
        }

        // Assume if it has a length property with a non-zero value
        // that that property is correct.
        if (obj.length > 0) {
            return false;
        }
        if (obj.length === 0) {
            return true;
        }

        // If it isn't an object at this point
        // it is empty, but it can't be anything *but* empty
        // Is it empty?  Depends on your application.
        if (typeof obj !== 'object') {
            return true;
        }

        // Otherwise, does it have any properties of its own?
        // Note that this doesn't handle
        // toString and valueOf enumeration bugs in IE < 9
        for (const key in obj) {
            if (hasOwnProperty.call(obj, key)) {
                return false;
            }
        }

        return true;
    }
    rad(x) {
        return (x * Math.PI) / 180;
    }

    getDistance(p1, p2) {
        var R = 6378137; // Earth’s mean radius in meter
        var dLat = this.rad(p2.lat - p1.lat);
        var dLong = this.rad(p2.lng - p1.lng);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(p1.lat)) *
                Math.cos(this.rad(p2.lat)) *
                Math.sin(dLong / 2) *
                Math.sin(dLong / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c;
        return d; // returns the distance in meter
    }
    showDistance(x) {
        return Math.round(10 * x) / 10;
    }

    getAcademyDistance(academy, defaultLocation = {}, unit = 'm') {
        let distance = 0;
        let currentLat = localStorage.getItem('latitude');
        let currentLng = localStorage.getItem('longitude');

        if (defaultLocation.lat && defaultLocation.lng) {
            currentLat = defaultLocation.lat;
            currentLng = defaultLocation.lng;
        }
        if (
            currentLat &&
            currentLng &&
            academy.ms_latitude &&
            academy.ms_longitude
        ) {
            let currentLocation = {
                lat: currentLat,
                lng: currentLng,
            };
            let academyLocation = {
                lat: academy.ms_latitude,
                lng: academy.ms_longitude,
            };
            distance = this.getDistance(currentLocation, academyLocation);
            if (unit === 'mile') {
                distance = this.showDistance(distance * 0.000621);
            } else distance = Math.round(distance);
        }
        return distance;
    }

    // getVersion() {
    //     return packageVersion.version;
    // }
    getCurrentAcademy(dispatch, number) {
        let options = {
            enableHighAccuracy: true,
            timeout: 0,
            maximumAge: 0,
        };

        const dispatchTest = useDispatch();

        const success = (pos) => {
            let crd = pos.coords;
            dispatchTest({
                type: siteActionType.GET_CURRENT_ACADEMY,
                lat: crd.latitude,
                long: crd.longitude,
                number: number,
            });
        };
        function error(err) {
            alert('get location error', err);
            // dispatch({
            //     type: siteActionType.GET_CURRENT_ACADEMY_FAILED,
            //     number: number,
            // });
        }

        navigator.geolocation.getCurrentPosition(success, error, options);
    }

    getMyLocation() {
        let options = {
            enableHighAccuracy: true,
            timeout: 5000,
            maximumAge: 0,
        };
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (response) => resolve(response),
                (error) => reject(error),
                options,
            );
        });
    }

    getLinkYoutube(string) {
        const regex = /<p>.*<\/p>/g;
        const filterPTags = string.match(regex);
        if (typeof filterPTags === 'object' && filterPTags.length > 0) {
            const link = filterPTags[0].replace(/<p>/, '').replace(/<\/p>/, '');
            return link;
        }

        return '';
    }

    convertCost(weeklyCost = '', locations, content, minCost = '') {
        let replaceContent = weeklyCost?.one
            ? weeklyCost?.one
            : minCost?.one || '';

        const venues = locations + ' venues';

        if (content.includes('$WeeklyCost')) {
            return content
                .replace('$WeeklyCost', replaceContent)
                .replace('$TotalVenues', venues);
        }

        return content
            .replace('$WeeklyCost', replaceContent)
            .replace('$TotalVenues', venues);
    }

    convertTrustPilot(rating, maxRate, review, content) {
        return content
            .replace('$TrustPilotRating', rating)
            .replace('$TrustPilotMaxRate', maxRate)
            .replace('$TrustPilotReviews', review);
    }

    convertToQuery(param) {
        return (
            '?' +
            Object.keys(param)
                .map(function (key) {
                    return (
                        encodeURIComponent(key) +
                        '=' +
                        encodeURIComponent(param[key])
                    );
                })
                .join('&')
        );
    }

    getDistanceFromLatLonInKm(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = (lat2 - lat1) * (Math.PI / 180);
        var dLon = (lon2 - lon1) * (Math.PI / 180);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(lat1 * (Math.PI / 180)) *
                Math.cos(lat2 * (Math.PI / 180)) *
                Math.sin(dLon / 2) *
                Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km

        return d;
    }

    // convertToKm(meters) {
    //     if (meters) {
    //         return parseInt(parseInt(meters, 10) * 0.001);
    //     }
    //     return '';
    // }
    getAge(birth) {
        return ~~((Date.now() - birth) / 31557600000);
    }

    getAllUrl(listSite) {
        const listUrl = [
            {
                loc: Constants.BaseURL,
                lastmod: new Date().toISOString(),
            },
        ];

        //parent site
        Constants.ROUTES.map((item) => {
            listUrl.push({
                loc: Constants.BaseURL + item,
                lastmod: new Date().toISOString(),
                priority: 0.7,
                changefreq: 'daily',
            });
        });
        //micro site
        Constants.MICRO_ROUTES.map((item) => {
            listSite.map((it) => {
                listUrl.push({
                    loc: `${Constants.BaseURL}/${it.ms_alias}${item}`,
                    lastmod: new Date().toISOString(),
                    priority: 0.7,
                    changefreq: 'daily',
                });
            });
        });

        return listUrl;
    }

    async getDetailMicrosite(nameFranchise, slug) {
        try {
            const listRes = await siteService.getListSite();
            const listSite = listRes?.data?.data?.lstSite;

            const item = listSite.find(
                (item) => nameFranchise === item.ms_alias,
            );

            if (isEmpty(item)) {
                return { data: [], listSite };
            }

            const siteDetail = await siteService.getDetailSite({
                id: item.ms_id,
                location: item.ms_id,
                slug: slug,
            });

            let data = { ...siteDetail?.data?.data };
            let dataCamp = { ...siteDetail?.data?.data?.dayCamp };
            if (isEmpty(data)) return { data: [], listSite };

            if (!isEmpty(data.masterData)) {
                data = Object.assign(data, data.masterData);
                if (data?.about && siteDetail?.data?.data?.about) {
                    data.about.cfg_image =
                        siteDetail?.data?.data?.about?.cfg_image;
                    data.about.cfg_mobileBanner =
                        siteDetail?.data?.data?.about?.cfg_mobileBanner;
                    data.about.cfg_content =
                        siteDetail?.data?.data?.about?.cfg_content;
                }
            }

            return { data, listSite, dataCamp };
        } catch (error) {
            return { data: [], listSite: [] };
        }
    }

    saveToLocal(data) {
        window.localStorage.setItem('dataPayment', JSON.stringify(data));
    }

    async onClickLocation(item, country) {
        try {
            const res = await siteService.getDetailSite({ id: item.ms_id });
            if (res.data.status == 200) {
                const item = res.data?.data?.site || {};
                localStorage.setItem('defaultAcademy', JSON.stringify(item));
                window.location.href = `${PathRoute.AliasHome(
                    country,
                    item?.ms_alias,
                )}`;
            }
        } catch (error) {
            // console.log(error);
        }
    }

    checkSubname(item) {
        // console.log(item, 'item');
        return `${item.ms_name}
        <p className="subname">${item.ms_subName.text || ''}</p>`;
        
    }

    checkSubnameListAcademy(item) {
        // console.log(item, 'item');
        return `${item.ms_name}<br>
        <span className="subnameListAcademy">${item.ms_subName.text || ''}</span>`;
        
    }

    renderItem(option) {
        if (!isEmpty(option?.ms_subName?.text)) {
            return (
                <span>
                    {`${option.ms_name}`}
                    {' - '}
                    <span
                        style={{
                            color: '#FF7100',
                        }}>{`${option.ms_subName.text}`}</span>{' '}
                </span>
            );
        }

        return <span>{option.ms_name}</span>;
    }

    makeSpace(number) {
        let string = "1";
        for(let i = 0; i < number ; i++) {
          string += " ";
        }
        return string;
    }

    convertLocation = (locationsIds, Courses) => {
        const locations = locationsIds?.map((el) => el);
        const group = [];
        locations?.map((currentValue, key) => {
            // group[currentValue?.ms_address + this.makeSpace(key)] = Courses?.filter(
            //     (el) => el?.location_id == currentValue?.pa_locationId
            // );
            const custom_name = currentValue?.ms_address + ` pa=${currentValue?.pa_locationId}`
            group[custom_name] = Courses?.filter(
                (el) => el?.location_id == currentValue?.pa_locationId
            );
        })
        return { ...group };
    };

    checkAcademy = (
        defaultAcademy,
        country,
        { routeWithAlias, routeWithCountry },
    ) => {
        if (!isEmpty(defaultAcademy)) {
            return routeWithAlias(country, defaultAcademy?.ms_alias);
        }
        return routeWithCountry(country || Constants.UK);
    };

    checkURL = (urlLocation, country) => {
        if (country === Constants.UK) {
            const splitArr = urlLocation.replace(urlLocation, '/');

            return splitArr;
        }
        const splitArr = urlLocation.split('/');
        splitArr[1] = country;
        const newURL = splitArr.join('/');
        return newURL;
    };

    checkCountry = (countryCode, country) => {
        if (countryCode === US && country === Constants.US) {
            return false;
        } else if (countryCode === US && country === Constants.UK) {
            return true;
        } else if (countryCode === GB && country === Constants.UK) {
            return false;
        } else if (countryCode === GB && country === Constants.US) {
            return true;
        }
    };

    navigateLocation = (countryCode, urlUS, urlUK) => {
        if (countryCode === US) {
            return urlUS;
        }
        return urlUK;
    };

    changeLocation = (countryCode, US, UK) => {
        if (countryCode === US) {
            return US;
        }
        return UK;
    };
    checkCurrency = (country, currencyUS, currencyUK) => {
        if (country === Constants.US) {
            return currencyUS;
        }
        return currencyUK;
    };
   formatDateTrackEvent = (sessionDate) => {
        const dateObject = new Date(sessionDate);
        const day = dateObject.getDate().toString().padStart(2, "0");
        const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
        const year = dateObject.getFullYear();
      
        return `${day}:${month}:${year}`;
      };

      formatTime = (time) => {
        const [timeParts, ampm] = time.split(" ");
        const [hourStr, minuteStr] = timeParts.split(":");
      
        const hour = parseInt(hourStr, 10);
        const minute = parseInt(minuteStr, 10);
      
        if (Number.isNaN(hour) || Number.isNaN(minute)) {
          return "";
        }
      
        let formattedHour = hour;
        if (ampm && ampm.toLowerCase() === "pm" && hour !== 12) {
          formattedHour += 12;
        } else if (ampm && ampm.toLowerCase() === "am" && hour === 12) {
          formattedHour = 0;
        }
        const formattedTime = `${formattedHour.toString().padStart(2, "0")}:${minute
          .toString()
          .padStart(2, "0")}`;
        return formattedTime;
      };

      filterUpcomingHolidayCamps = (holidayCamps) => {
        const now = new Date();
      
        return holidayCamps.filter((holidayCamp) => {
          return holidayCamp.course_dates.some((courseDate) => {
            const [year, month, day] = courseDate.day_date.split('-').map(Number);
            const [hours, minutes, seconds] = courseDate.time_start.split(':').map(Number);
            const eventStartTime = new Date(year, month - 1, day, hours, minutes, seconds);
            return eventStartTime > now;
          });
        });
      };      
}

export default new Utils();
