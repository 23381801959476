const { useState } = require("react");
const { useEffect } = require("react");

const getCountryLocal = () => {
    const [country, setCountry] = useState('');

    useEffect(() => {
        setCountry(localStorage.getItem('country') || '')
    },[])

    return country
}

export default getCountryLocal
