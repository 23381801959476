export const loginActionType = {
    LOGIN_SUBMIT: 'LOGIN_SUBMIT',
    LOGIN_SUCCESS: 'LOGIN_SUCCESS',
    LOGIN_FAILED: 'LOGIN_FAILED',
};

export const headerActionType = {
    CHANGE_LOCATION: 'CHANGE_LOCATION',
    CLOSE_LOCATION: 'CLOSE_LOCATION',
    CLOSE_MAP_POPUP: 'CLOSE_MAP_POPUP',
    CHANGE_COUNTRY: 'CHANGE_COUNTRY',
    CHANGE_ACADEMY: 'CHANGE_ACADEMY',
    DELETE_ACADEMY: 'DELETE_ACADEMY',
    OPEN_TRY_A_FREE: 'OPEN_TRY_A_FREE',
    CLOSE_TRY_A_FREE: 'CLOSE_TRY_A_FREE',
};

export const siteActionType = {
    ALLOW_LOCATION: 'ALLOW_LOCATION',

    GET_SITE_HAS_CAMP: 'GET_SITE_HAS_CAMP',
    GET_SITE_HAS_CAMP_SUCCESS: 'GET_SITE_HAS_CAMP_SUCCESS',
    GET_SITE_HAS_CAMP_FAILED: 'GET_SITE_HAS_CAMP_FAILED',

    GET_DETAIL_SITE: 'GET_DETAIL_SITE',
    GET_DETAIL_SITE_SUCCESS: 'GET_DETAIL_SITE_SUCCESS',
    GET_DETAIL_SITE_FAILED: 'GET_DETAIL_SITE_FAILED',

    BOOK_TRAINING: 'BOOK_TRAINING',
    BOOK_TRAINING_SUCCESS: 'BOOK_TRAINING_SUCCESS',
    BOOK_TRAINING_FAILED: 'BOOK_TRAINING_FAILED',

    SEARCH_NEARBY: 'SEARCH_NEARBY',
    SEARCH_NEARBY_SUCCESS: 'SEARCH_NEARBY_SUCCESS',
    SEARCH_NEARBY_FAILED: 'SEARCH_NEARBY_FAILED',
    SELECT_ACADEMY: 'SELECT_ACADEMY',

    FIND_NEARBY: 'FIND_NEARBY',
    FIND_NEARBY_SUCESS: 'FIND_NEARBY_SUCESS',
    FIND_NEARBY_FAILED: 'FIND_NEARBY_FAILED',

    GET_CURRENT_ACADEMY: 'GET_CURRENT_ACADEMY',
    GET_CURRENT_ACADEMY_SUCCESS: 'GET_CURRENT_ACADEMY_SUCCESS',
    GET_CURRENT_ACADEMY_FAILED: 'GET_CURRENT_ACADEMY_FAILED',

    PICK_DEFAULT_ACADEMY: 'PICK_DEFAULT_ACADEMY',
    SELECTED_MARKER: 'SELECTED_MARKER',
    KEY_CODE: 'KEY_CODE',

    GET_LIST_COURSE: 'GET_LIST_COURSE',
    GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
    GET_LIST_COURSE_FAILED: 'GET_LIST_COURSE_FAILED',

    GET_LIST_COURSE_NEARLY: 'GET_LIST_COURSE_NEARLY',
    GET_LIST_COURSE_NEARLY_SUCCESS: 'GET_LIST_COURSE_NEARLY_SUCCESS',
    GET_LIST_COURSE_NEARLY_FAILED: 'GET_LIST_COURSE_NEARLY_FAILED',

    GET_LIST_COURSE_AVAILABLE: 'GET_LIST_COURSE_AVAILABLE',
    // GET_LIST_COURSE_AVAILABLE_SUCCESS: 'GET_LIST_COURSE_AVAILABLE',
    // GET_LIST_COURSE_AVAILABLE_FAILDED: 'GET_LIST_COURSE_AVAILABLE',

    COURSE_START_DATE: 'COURSE_START_DATE',
    COURSE_START_DATE_SUCCESS: 'COURSE_START_DATE_SUCCESS',
    COURSE_START_DATE_FAILED: 'COURSE_START_DATE_FAILED',

    BOOK_COURSE: 'BOOK_COURSE',
    BOOK_COURSE_SUCCESS: 'BOOK_COURSE_SUCCESS',
    BOOK_COURSE_FAILED: 'BOOK_COURSE_FAILED',

    BOOK_COURSE_SIGNUP: 'BOOK_COURSE_SIGNUP',
    BOOK_COURSE_SIGNUP_SUCCESS: 'BOOK_COURSE_SIGNUP_SUCCESS',
    BOOK_COURSE_SIGNUP_FAILED: 'BOOK_COURSE_SIGNUP_FAILED',

    BOOK_EVENT_SIGNUP: 'BOOK_EVENT_SIGNUP',
    BOOK_EVENT_SIGNUP_SUCCESS: 'BOOK_EVENT_SIGNUP_SUCCESS',
    BOOK_EVENT_SIGNUP_FAILED: 'BOOK_EVENT_SIGNUP_FAILED',

    ADD_WAITING: 'ADD_WAITING',
    ADD_WAITING_SUCCESS: 'ADD_WAITING_SUCCESS',
    ADD_WAITING_FAILED: 'ADD_WAITING_FAILED',

    GET_BOOKING: 'GET_BOOKING',
    GET_BOOKING_SUCCESS: 'GET_BOOKING_SUCCESS',
    GET_BOOKING_FAILED: 'GET_BOOKING_FAILED',

    EVENT_DATE: 'EVENT_DATE',
    EVENT_DATE_SUCCESS: 'EVENT_DATE_SUCCESS',
    EVENT_DATE_FAILED: 'EVENT_DATE_FAILED',

    FIND_NEARBY_ACADEMY: 'FIND_NEARBY_ACADEMY',
    FIND_NEARBY_ACADEMY_SUCCESS: 'FIND_NEARBY_ACADEMY_SUCCESS',
    FIND_NEARBY_ACADEMY_FAILED: 'FIND_NEARBY_ACADEMY_FAILED',

    SEND_EMAIL: 'SEND_EMAIL',
    SEND_EMAIL_SUCCESS: 'SEND_EMAIL_SUCCESS',
    SEND_EMAIL_FAILED: 'SEND_EMAIL_FAILED',

    GET_FOOTER_CONFIG: 'GET_FOOTER_CONFIG',
    GET_FOOTER_CONFIG_SUCCESS: 'GET_FOOTER_CONFIG_SUCCESS',
    GET_FOOTER_CONFIG_FAILED: 'GET_FOOTER_CONFIG_FAILED',

    REFRESH_DEFAULT_DATA: 'REFRESH_DEFAULT_DATA',
    REFRESH_DEFAULT_DATA_SUCCESS: 'REFRESH_DEFAULT_DATA_SUCCESS',
    REFRESH_DEFAULT_DATA_FAILED: 'REFRESH_DEFAULT_DATA_FAILED',

    GET_AREA_LOCATION: 'GET_AREA_LOCATION',
    GET_AREA_LOCATION_SUCCESS: 'GET_AREA_LOCATION_SUCCESS',
    GET_AREA_LOCATION_FAILED: 'GET_AREA_LOCATION_FAILED',

    GET_AREA: 'GET_AREA',
    GET_AREA_SUCCESS: 'GET_AREA_SUCCESS',
    GET_AREA_FAILED: 'GET_AREA_FAILED',

    GET_LIST_NEWS: 'GET_LIST_NEWS',
    GET_LIST_NEWS_SUCCESS: 'GET_LIST_NEWS_SUCCESS',
    GET_LIST_NEWS_FAILED: 'GET_LIST_NEWS_FAILED',

    //! US
    GET_AREA_US: 'GET_AREA_US',
    GET_AREA_US_SUCCESS: 'GET_AREA_US_SUCCESS',
    GET_AREA_US_FAILED: 'GET_AREA_US_FAILED',

    GET_AREA_US_LOCATION: 'GET_AREA_US_LOCATION',
    GET_AREA_US_LOCATION_SUCCESS: 'GET_AREA_US_LOCATION_SUCCESS',
    GET_AREA_US_LOCATION_FAILED: 'GET_AREA_US_LOCATION_FAILED',

    GET_AREA_BOTH: 'GET_AREA_BOTH',
    GET_AREA_BOTH_SUCCESS: 'GET_AREA_BOTH_SUCCESS',
    GET_AREA_BOTH_FAILED: 'GET_AREA_BOTH_FAILED',
};

export default {
    SAVE_lIST_SITE: 'SAVE_lIST_SITE',

    GET_LIST_SITE: 'GET_LIST_SITE',
    GET_LIST_SITE_SUCCESS: 'GET_LIST_SITE_SUCCESS',
    GET_LIST_SITE_FAILED: 'GET_LIST_SITE_FAILED',

    GET_FRANCHISE_DETAIL_REQUEST: 'GET_FRANCHISE_DETAIL_REQUEST',
    GET_FRANCHISE_DETAIL_SUCCESS: 'GET_FRANCHISE_DETAIL_SUCCESS',
    GET_FRANCHISE_DETAIL_FAILED: 'GET_FRANCHISE_DETAIL_FAILED',

    CLEAR_FRANCHISE_DETAIL: 'CLEAR_FRANCHISE_DETAIL',

    GET_HOME: 'GET_HOME',
    GET_HOME_SUCCESS: 'GET_HOME_SUCCESS',
    GET_HOME_FAILED: 'GET_HOME_FAILED',

    GET_DETAIL_SITE: 'GET_DETAIL_SITE',
    GET_DETAIL_SITE_SUCCESS: 'GET_DETAIL_SITE_SUCCESS',
    GET_DETAIL_SITE_FAILED: 'GET_DETAIL_SITE_FAILED',
    CLEAR_DETAIL_SITE: 'CLEAR_DETAIL_SITE',

    GET_ABOUT: 'GET_ABOUT',
    GET_ABOUT_SUCCESS: 'GET_ABOUT_SUCCESS',
    GET_ABOUT_FAILED: 'GET_ABOUT_FAILED',

    GET_LIST_NEWS: 'GET_LIST_NEWS',
    GET_LIST_NEWS_SUCCESS: 'GET_LIST_NEWS_SUCCESS',
    GET_LIST_NEWS_FAILED: 'GET_LIST_NEWS_FAILED',

    GET_LIST_ALL_NEW: 'GET_LIST_ALL_NEW',
    GET_LIST_ALL_NEW_SUCCESS: 'GET_LIST_ALL_NEW_SUCCESS',
    GET_LIST_ALL_NEW_FAILED: 'GET_LIST_ALL_NEW_FAILED',

    DETAIL_ARTICLE: 'DETAIL_ARTICLE',
    DETAIL_ARTICLE_SUCCESS: 'DETAIL_ARTICLE_SUCCESS',
    DETAIL_ARTICLE_FAILED: 'DETAIL_ARTICLE_FAILED',

    FIND_NEARBY_ACADEMY: 'FIND_NEARBY_ACADEMY',
    FIND_NEARBY_ACADEMY_SUCCESS: 'FIND_NEARBY_ACADEMY_SUCCESS',
    FIND_NEARBY_ACADEMY_FAILED: 'FIND_NEARBY_ACADEMY_FAILED',

    GET_LIST_COURSE: 'GET_LIST_COURSE',
    GET_LIST_COURSE_SUCCESS: 'GET_LIST_COURSE_SUCCESS',
    GET_LIST_COURSE_FAILED: 'GET_LIST_COURSE_FAILED',

    GET_LIST_COURSE_NEARLY: 'GET_LIST_COURSE_NEARLY',
    GET_LIST_COURSE_NEARLY_SUCCESS: 'GET_LIST_COURSE_NEARLY_SUCCESS',
    GET_LIST_COURSE_NEARLY_FAILED: 'GET_LIST_COURSE_NEARLY_FAILED',

    COURSE_START_DATE: 'COURSE_START_DATE',
    COURSE_START_DATE_SUCCESS: 'COURSE_START_DATE_SUCCESS',
    COURSE_START_DATE_FAILED: 'COURSE_START_DATE_FAILED',

    BOOK_COURSE_SIGNUP: 'BOOK_COURSE_SIGNUP',
    BOOK_COURSE_SIGNUP_SUCCESS: 'BOOK_COURSE_SIGNUP_SUCCESS',
    BOOK_COURSE_SIGNUP_FAILED: 'BOOK_COURSE_SIGNUP_FAILED',

    BOOK_COURSE: 'BOOK_COURSE',
    BOOK_COURSE_SUCCESS: 'BOOK_COURSE_SUCCESS',
    BOOK_COURSE_FAILED: 'BOOK_COURSE_FAILED',

    GET_FOOTER_CONFIG: 'GET_FOOTER_CONFIG',
    GET_FOOTER_CONFIG_SUCCESS: 'GET_FOOTER_CONFIG_SUCCESS',
    GET_FOOTER_CONFIG_FAILED: 'GET_FOOTER_CONFIG_FAILED',

    GET_POLICY: 'GET_POLICY',
    GET_POLICY_SUCCESS: 'GET_POLICY_SUCCESS',
    GET_POLICY_FAILED: 'GET_POLICY_FAILED',
    GET_POLICY_INDEX: 'GET_POLICY_INDEX',

    CLEAR_SEND_EMAIL: 'CLEAR_SEND_EMAIL',

    SEND_FORM: 'SEND_FORM',
    SEND_FORM_SUCCESS: 'SEND_FORM_SUCCESS',
    SEND_FORM_FAILED: 'SEND_FORM_FAILED',
    CLEAR_SEND_FORM: 'CLEAR_SEND_FORM',

    CHECK_MAIL: 'CHECK_MAIL',
    CHECK_MAIL_SUCCESS: 'CHECK_MAIL_SUCCESS',
    CHECK_MAIL_FAILED: 'CHECK_MAIL_FAILED',
    CLEAR_CHECK_MAIL: 'CLEAR_CHECK_MAIL',

    GET_LIST_FAQ: 'GET_LIST_FAQ',
    GET_LIST_FAQ_SUCCESS: 'GET_LIST_FAQ_SUCCESS',
    GET_LIST_FAQ_FAILED: 'GET_LIST_FAQ_FAILED',

    SEARCH_FAQ: 'SEARCH_FAQ',
    SEARCH_FAQ_SUCCESS: 'SEARCH_FAQ_SUCCESS',
    SEARCH_FAQ_FAILED: 'SEARCH_FAQ_FAILED',

    RESEND_EMAIL: 'RESEND_EMAIL',
    RESEND_EMAIL_SUCCESS: 'RESEND_EMAIL_SUCCESS',
    RESEND_EMAIL_FAILED: 'RESEND_EMAIL_FAILED',

    SAVE_DEFAULT_CONFIG_TYPEFORM: 'SAVE_DEFAULT_CONFIG_TYPEFORM',
};
