import { isEmpty } from 'lodash';
import Constants from './Constants';

function convertURL(country, url) {
    if (!isEmpty(country) && country === Constants.UK) {
        const newURL = url.replace('/uk', '');
        return newURL;
    }

    return url;
}

let PathRoute = {
    //! UK

    Home: (country) => convertURL(country, `/${country}/`),
    AliasHome: (country, alias) => convertURL(country, `/${country}/${alias}`),
    Contact: (country) => convertURL(country, `/${country}/contact-us`),
    HolidayCamp: (country) => convertURL(country, `/${country}/holiday-camps`),
    HolidayCampWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/holiday-camps`),
    OneTraining: (country) =>
        convertURL(country, `/${country}/1-on-1-training`),
    OneTrainingWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/1-on-1-training`),
    BirthdayParty: (country) =>
        convertURL(country, `/${country}/birthday-parties`),
    BirthdayPartyWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/birthday-parties`),
    WeeklyTraining: (country) =>
        convertURL(country, `/${country}/weekly-football-training`),
    WeeklyTrainingWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/weekly-football-training`),
    // Franchise: '/franchise/:alias-:id([0-9]+)',
    FindClass: (country) => convertURL(country, `/${country}/find-class`),
    Franchise: (country) => convertURL(country, `/${country}/:title`),
    AboutUs: (country) => convertURL(country, `/${country}/about-us`),
    Location: (country) => convertURL(country, `/${country}/location`),
    ListQNA: (country) => convertURL(country, `/${country}/faqs`),
    DetailQNA: (country) => convertURL(country, `/${country}/faqs/:cateAlias`),
    HomeNews: (country) => convertURL(country, `/${country}/news`),
    NewWithAlias: (country, alias) =>
        convertURL(country, `/${country}/news/${alias}`),
    NewWithAliasAcademy: (country, academyAlias, alias) =>
        convertURL(country, `/${country}/${academyAlias}/news/${alias}`),
    HomeNewsWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/news`),
    ListNews: (country) => convertURL(country, `/${country}/news/:cateAlias`),
    DetailNews: (country) =>
        convertURL(country, `/${country}/news/:alias-:id([0-9]+)`),
    BookTrialTraining: (country) =>
        convertURL(country, `/${country}/book-trial-training`),
    BookTrialTrainingWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/book-trial-training`),
    BookTrialTrainingSuccess: (country) =>
        convertURL(country, `/${country}/book-free-session`),
    BookTrialTrainingSuccessWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias ?? ''}/book-free-session`),
    PreEnrolTheFun: (country) =>
        convertURL(country, `/${country}/pre-enroll-the-fun`),
    PreEnrolTheFunWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/pre-enroll-the-fun`),
    BookTrialCamp: (country) =>
        convertURL(country, `/${country}/book-trial-camp`),
    ThankYou: (country) => convertURL(country, `/${country}/thank-you`),
    AcademyNews: (country) => convertURL(country, `/${country}/:alias/news`),
    Policy: (country) => convertURL(country, `/${country}/policies`),
    PolicyWithParam: (country) =>
        convertURL(country, `/${country}/policies/:title`),
    PoliciesPrivacy: (country) =>
        convertURL(country, `/${country}/policies/privacy`),
    SchoolTraining: (country) =>
        convertURL(country, `/${country}/school-training`),
    Error: (country) => convertURL(country, `/${country}/error`),
    JoinUs: (country) => convertURL(country, `/${country}/join-us`),
    JoinUsWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/join-us`),
    Coaching: (country) => convertURL(country, `/${country}/join-us/coaching`),
    ParentHost: (country) =>
        convertURL(country, `/${country}/join-us/parent-host`),
    ThankYouWithAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/thank-you`),
    SiteMap: (country) => convertURL(country, `/${country}/sitemap`),
    BookingPaymentThankYou: (country) =>
        convertURL(country, `/${country}/booking-payment-thank-you`),
    BookingPaymentThankYouAlias: (country, alias) =>
        convertURL(country, `/${country}/${alias}/booking-payment-thank-you`), 

    FaqCateAliasID: (country, cateAlias, atcId) =>
        convertURL(country, `/${country}/faqs/${cateAlias}#${atcId}`),
    FaqAlias: (country, cateAlias) =>
        convertURL(country, `/${country}/faqs/${cateAlias}`),
    AreaLocationWithAlias: (country,areaLocation) => convertURL(country, `/${country}/${areaLocation}/area`),
    // Home: (country) => `/${country}/`,
    // AliasHome: (country, alias) => `/${country}/${alias}`,
    // Contact: (country) => `/${country}/contact-us`,
    // HolidayCamp: (country) => `/${country}/holiday-camps`,
    // HolidayCampWithAlias: (country, alias) =>
    //     `/${country}/${alias}/holiday-camps`,
    // OneTraining: (country) => `/${country}/1-on-1-training`,
    // OneTrainingWithAlias: (country, alias) =>
    //     `/${country}/${alias}/1-on-1-training`,
    // BirthdayParty: (country) => `/${country}/birthday-parties`,
    // BirthdayPartyWithAlias: (country, alias) =>
    //     `/${country}/${alias}/birthday-parties`,
    // WeeklyTraining: (country) => `/${country}/weekly-football-training`,
    // WeeklyTrainingWithAlias: (country, alias) =>
    //     `/${country}/${alias}/weekly-football-training`,
    // // Franchise: '/franchise/:alias-:id([0-9]+)',
    // Franchise: (country) => `/${country}/:title`,
    // AboutUs: (country) => {
    //     `/${country}/about-us`;
    // },
    // Location: (country) => `/${country}/location`,
    // ListQNA: (country) => `/${country}/faqs`,
    // DetailQNA: (country) => `/${country}/faqs/:cateAlias`,
    // HomeNews: (country) => `/${country}/news`,
    // NewWithAlias: (country, alias) => `/${country}/news/${alias}`,
    // NewWithAliasAcademy: (country, academyAlias, alias) =>
    //     `/${country}/${academyAlias}/news/${alias}`,
    // HomeNewsWithAlias: (country, alias) => `/${country}/${alias}/news`,
    // ListNews: (country) => `/${country}/news/:cateAlias`,
    // DetailNews: (country) => `/${country}/news/:alias-:id([0-9]+)`,
    // BookTrialTraining: (country) => `/${country}/book-trial-training`,
    // BookTrialTrainingWithAlias: (country, alias) =>
    //     `/${country}/${alias}/book-trial-training`,
    // BookTrialCamp: (country) => `/${country}/book-trial-camp`,
    // ThankYou: (country) => `/${country}/thank-you`,
    // AcademyNews: (country) => `/${country}/:alias/news`,
    // Policy: (country) => `/${country}/policies`,
    // PolicyWithParam: (country) => `/${country}/policies/:title`,
    // PoliciesPrivacy: (country) => `/${country}/policies/privacy`,
    // SchoolTraining: (country) => `/${country}/school-training`,
    // Error: (country) => `/${country}/error`,
    // JoinUs: (country) => `/${country}/join-us`,
    // JoinUsWithAlias: (country, alias) => `/${country}/${alias}/join-us`,
    // Coaching: (country) => `/${country}/join-us/coaching`,
    // ParentHost: (country) => `/${country}/join-us/parent-host`,
    // ThankYouWithAlias: (country, alias) => `/${country}/${alias}/thank-you`,
    // SiteMap: (country) => `/${country}/sitemap.xml`,

    // FaqCateAliasID: (country, cateAlias, atcId) =>
    //     `/${country}/faqs/${cateAlias}#${atcId}`,
    // FaqAlias: (country, cateAlias) => `/${country}/faqs/${cateAlias}`,
};

export default PathRoute;
