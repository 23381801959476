import * as React from "react"

const FlagAE = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 6 3">
      <path d="m0,0h6v3H0"/>
      <path d="m0,0h6v2H0" fill="#fff"/>
      <path d="m0,0h6v1H0" fill="#00732f"/>
      <path d="m0,0h1.5v3H0" fill="#f00"/>
    </svg>
)

export default FlagAE
