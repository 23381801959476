import Constants from './Constants';
import FlagUK from 'src/common/FlagUK';
import FlagUS from 'src/common/FlagUS';
import FlagAE from 'src/common/FlagAE';

const countries = [
    {
        label: 'United Kingdom',
        country: Constants.UK,
        lang: 'en-gb',
        flag: FlagUK,
    },
    {
        label: 'United states',
        country: Constants.US,
        lang: 'en-us',
        flag: FlagUS,
    },
    {
        label: 'United Arab Emirates',
        country: Constants.AE,
        lang: 'ar-ae',
        flag: FlagAE,
    },
];

export default countries;
